import { createContext } from 'preact'
import { useCallback, useContext, useState } from 'preact/hooks'

const ModalContext = createContext({
  contactManagerIsOpened: false,
  applyIsOpened: false,
  ratesIsOpened: false,
  selectedRate: null,
  selectedEducationCourse: null,
  resumeModalIsOpened: false,
  successfullyModalIsOpened: false,
  areaCooperationModalIsOpened: null,
  setContactManagerModalState: () => {},
  setRatesModalState: () => {},
  setApplyModalState: () => { },
  setSelectedEducationCourse: () => {},
  setResumeModalState: () => {},
  setSuccessfullyModalState: () => {},
  setAreaCooperationModalState: () => {},

})

export const useModal = () => useContext(ModalContext)

export const ModalProvider = (props) => {
  const [modalState, setModalState] = useState({
    contactManagerIsOpened: false,
      resumeModalIsOpened: false,
      applyIsOpened: false,
    ratesIsOpened: false,
    selectedRate: null,
    selectedEducationCourse: null,
    successfullyModalIsOpened: false,
    AreaCooperationModalIsOpened: null
  })

  const setContactManagerModalState = useCallback(
    (state) =>
      setModalState({
        contactManagerIsOpened: state,
        ratesIsOpened: false,
          resumeModalIsOpened: false,
        applyIsOpened: false,
        successfullyModalIsOpened: false,
        AreaCooperationModalIsOpened: null
      }),
    []
  )

  const setRatesModalState = useCallback(
    (state, selectedRate) =>
      setModalState({
        contactManagerIsOpened: false,
        ratesIsOpened: state,
          resumeModalIsOpened: false,
        applyIsOpened: false,
        selectedRate,
        successfullyModalIsOpened: false,
        AreaCooperationModalIsOpened: null
      }),
    []
  )

  const setResumeModalState = useCallback(
    (state) =>
      setModalState({
          contactManagerIsOpened: false,
          resumeModalIsOpened: state,
          ratesIsOpened: false,
        applyIsOpened: false,
        successfullyModalIsOpened: false,
        AreaCooperationModalIsOpened: null
      }),
    []
  )

    const setApplyModalState = useCallback(
        (state) =>
            setModalState({
                contactManagerIsOpened: false,
                ratesIsOpened: false,
                resumeModalIsOpened: false,
                applyIsOpened: state,
              successfullyModalIsOpened: false,
              AreaCooperationModalIsOpened: null
            }),
        []
    )

  const setSelectedEducationCourse = useCallback(
    (state) =>
      setModalState({
        selectedEducationCourse: state
      }),
    []
  )

  const setAreaCooperationModalState = useCallback(
    (state, selectedArea) =>
      setModalState({
        contactManagerIsOpened: false,
        ratesIsOpened: null,
        resumeModalIsOpened: false,
        applyIsOpened: false,
        selectedArea,
        successfullyModalIsOpened: false,
        areaCooperationModalIsOpened: state
      }),
    []
  )

  const setSuccessfullyModalState = useCallback(
    (state) =>
      setModalState({
        contactManagerIsOpened: false,
        ratesIsOpened: false,
        resumeModalIsOpened: false,
        applyIsOpened: false,
        successfullyModalIsOpened: state,
        AreaCooperationModalIsOpened: null
      }),
    []
  )

  return (
    <ModalContext.Provider
      value={{ ...modalState, setContactManagerModalState, setRatesModalState, setApplyModalState, setSelectedEducationCourse, setResumeModalState, setSuccessfullyModalState, setAreaCooperationModalState }}
    >
      {props.children}
    </ModalContext.Provider>
  )
}
