import { Router } from 'preact-router'
import Home from '../routes/home'
import { ModalProvider } from '../lib/modal'
import Organization from '../routes/organization'
import Offer from '../routes/offer'
import PrivacyPolicy from '../routes/privacyPolicy'
import PersonalDataAgreement from "../routes/personalDataAgreement";

const App = () => (
  <div id='app'>
    <ModalProvider>
      <Router>
        <Home path='/' />
        <Organization path='/organization/:type' />
        <Offer path='/assets/offer' />
        <PrivacyPolicy path='/assets/privacyPolicy' />
        <PersonalDataAgreement path='/assets/personalDataAgreement' />
      </Router>
    </ModalProvider>
  </div>
)

export default App
